<template>
	<v-row class="background">
		<v-col class="col-12"></v-col>
		<v-col class="col-12">
			<h2 class="clinic_text_h2 obvodka font-weight-bold ma-0">Ветеринарная клиника</h2>
			<h1 class="clinic_name obvodka primary--text ma-0">КОНДОР</h1>
			<h3 class="ma-0 obvodka">Здоровый питомец - счастливый владелец</h3>
		</v-col>
		<v-col class="col-lg-3 col-md-4 col-sm-6 col-12 py-0"><CallToAction></CallToAction></v-col>
		<v-col class="col-lg-3 col-md-4 col-sm-6 col-12 py-0"><v-btn color="white" block rounded  large href="https://t.me/kondor_vet">Написать в телеграм</v-btn></v-col>
	</v-row>
</template>

<script type="text/javascript">
import CallToAction from '@/components/CallToAction.vue'

export default {
	name: 'HEAD01',
	components: {
		CallToAction,
	},

}
</script>


<style>
	
</style>


