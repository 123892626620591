<template>

	<Block color="secondary">
		<template v-slot:title>Узнайте что о нас говорят</template>
		<template v-slot:subtitle>Оснвное правило нашей клиники - внимательное и чуткое отношение к животном и его владельцу</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-6 col-md-4" v-for="f in feedbacks" :key="f.user">
					<v-card width="300px" height="300px" class="justify-center ma-auto" :href="f.link">
						<v-card-title class="ma-0 pb-0">{{ f.user }}</v-card-title>
						<v-rating v-model="f.raiting" length="5" readonly color="red"></v-rating>
						<v-card-text class="px-2 pt-0" v-html="f.text"></v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</template>
	</Block>

</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'

export default {
	name: 'PageFeedback',
	components: {
		Block,
	},
	data(){
		return {
			feedbacks: [
				{user:'Анастасия Нестерова', raiting:5, date:"18.01.2023", link:'https://yandex.ru/maps/org/kondor/13274471258/reviews/?ll=37.537541%2C55.660094&tab=reviews&z=16.6', text:'Рекомендую клинику, так как не первый год мы посещаем наших врачей Максима Сергеевича и Екатерину Сергеевну. Здесь мы получаем квалифицированную помощь, а при необходимости хороший уход за животными в стационаре (испытали на своем любимце, Саймоне, которого вернули к жизни).'},
				{user:'Александра', raiting:5, date:'08.01.2023', text:'Хорошая ветклиника , персонал шикарный, все приветливые, вежливые, все объяснят, расскажут, предложат варианты, очень внимательно относятся к животным и к людям. Теперь только туда, всем советую', link:'https://yandex.ru/maps/org/kondor/13274471258/reviews/?ll=37.537541%2C55.660094&tab=reviews&z=16.6'},
				{user:'Илья Ожегов', raiting:5, date:'12.01.2023', text:'Вакцинировали кошку. Всё прошло быстро и без проблем. Её осмотрели, рассказали о всех нюансах. Большое спасибо', link:'https://yandex.ru/maps/org/kondor/13274471258/reviews/?ll=37.537541%2C55.660094&tab=reviews&z=16.6'},
			],
		}
	},

}
</script>






