<template>
	<Block id="about">
		<template v-slot:title>Расскажем о нашей ВК «Кондор» и наше видение</template>
		<template v-slot:subtitle>Мы предлагаем Вам и Вашему питомцу качественную ветеринарную помощь, здоровье и долголетие</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-4">
					<v-img src="/img/about_img.jpg"></v-img>
				</v-col>
				<v-col class="col-12 col-sm-8">
					<v-col class="col-12 ma-0 pa-0">
						<p>Мы, ветеринарная клиника, работаем по принципам доказательной медицины-это значит, что в нашей практике мы используем препараты и алгоритмы терапии, которые имеют под собой доказательную базу. </p>
						<p>Наши ветеринарные врачи проходят обучение постоянно повышая свою квалификацию в различных областях ветеринарной науки. </p>
						<p>Наши специалисты являются участниками и слушателями различных ветеринарных мероприятий (вебинары, лекции, семинары, мастер-классы)</p>
						<p>Наше кредо-главное диагноз. Мы работаем для животных и с животными, а также понимаем что здоровый питомец-счастливый владелец! Поэтому мы стараемся больше иметь возможности профилактировать болезни. </p>
						<p>В нашей клинике имеется все необходимое оборудование для диагностики, терапии, хирургии, и оказания экстренной помощи.</p>
						<p>Просторное помещение, которое отвечает требованиям Pet Friendly/Cat Friendly</p>
						<p>Отдельно стоящее здание ВК «Кондор» позволяет нам принимать тяжелых пациентов на круглосуточный стационар, что немаловажно для круглосуточного мониторинга.</p>
						<p>Две отдельные хирургии позволяют нам проводить хирургические манипуляции различной сложности.</p>
						<p>Собственная лаборатория помогает в диагностике болезней «здесь-и-сейчас», что позволяет увеличить шансы на выздоровление и позволить принять быстрое решение в критической ситуации, которая может угрожать жизни пациента.</p>
						<p>Мы стремимся сделать жизнь питомцев комфортнее, здоровее, что благоприятно сказывается на долголетии. </p>
						<p>Получите бесплатную консультацию по уходу, вакцинации, профилактике болезней «Закажите обратный звонок», мы перезвоним в удобное для вас время</p>
					</v-col>
					<v-col class="col-12">
						<v-row>
							<v-col class="col-12 col-sm-4">
								<v-row><span class="clinic_text_inform">9 лет</span></v-row>
								<v-row>делаем животных здоровыми, а их хозяев счастливыми</v-row>
							</v-col>
							<v-col class="col-12 col-sm-4">
								<v-row><span class="clinic_text_inform">39 420</span></v-row>
								<v-row>счастливых питомца получили качественное лечение</v-row>
							</v-col>
							<v-col class="col-12 col-sm-4">
								<v-row><span class="clinic_text_inform">11 826</span></v-row>
								<v-row>проведено успешных операций</v-row>
							</v-col>
						</v-row>
					</v-col>
				</v-col>
			</v-row>
		</template>
	</Block>

</template>

<script type="text/javascript">

import Block from '@/components/Block.vue'


export default {
	name: 'About',
	components: {
		Block,
	},
	data(){
		return {
		}
	},

}
</script>

<style>
p {
	margin-bottom: 5px !important;
}
</style>



