<template>
	<v-app>
		<!--<div style="z-index:100; position:fixed; top:50%; left:50%;"><v-alert>Оператор перезвонит в течении 24 часов</v-alert></div>	-->
		<v-app-bar app  color="white" elevate-on-scroll height="80">	

			<v-col class="col-10 col-sm-6 col-md-7 col-lg-2">
				<v-img src="img/logo_mini.png" max-width="200" alt="Ветеринарная клиника Кондор" ></v-img>
			</v-col>

			<v-col class="d-none d-lg-block col-lg-7 ">
				<center>
					<v-btn v-for="m in menu" :key="m.name" text color="primary" :href="m.link">{{ m.name }}</v-btn>
				</center>
			</v-col>

			<v-col class="d-none d-sm-block col-sm-5 col-md-4 col-lg-3">
				<v-row class='pl-10 '>
					<v-col class="col-12 text-center ma-0 pa-0 "><v-btn href="tel:+74997130777" class="text-h5" text>+7(499) 713-07-77</v-btn></v-col>
					<v-col class="col-4 pa-0 text-center"><v-btn href="https://t.me/kondor_vet"  class="dinamic_link" fab small text><center><v-img src="img/icon_telegram.png" height="24" width="24"></v-img></center></v-btn></v-col>
					<v-col class="col-4 pa-0 text-center"><v-btn href="https://vk.com/kondorvet"  fab small text><center><v-img src="img/icon_vk.png" height="24" width="24"></v-img></center></v-btn></v-col>
					<v-col class="col-4 pa-0 text-center"><v-btn  fab small text href="mailto:info@kondorvet.ru?subject=Обращение в ВК Кондор"><center><v-img src="img/icon_email.png" height="24" width="24"></v-img></center></v-btn></v-col>
				</v-row>
			</v-col>

			<v-col class="col-2 col-sm-1 d-lg-none">
				<v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
			</v-col>
			
		</v-app-bar>

		<v-navigation-drawer v-model="drawer" absolute temporary>
			<v-list nav dense>
				<v-list-item-group v-model="group" active-class="deep-purple--text text--accent-4">
					<v-list-item v-for="m in menu" :key="m.name">
						<v-list-item-icon><v-icon v-html="m.mdi"></v-icon></v-list-item-icon>
						<v-list-item-title v-html="m.name"></v-list-item-title>
					</v-list-item>
				</v-list-item-group>
			</v-list>
		</v-navigation-drawer>

		
		<v-main>
			<!--
			<v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="600" >
				<v-container style="height: 1500px; padding: 120px 0px 0px 0px;" >
			<v-sheet id="scrolling-techniques-7" class="overflow-y-auto"  >
				<v-container style="padding: 120px 0px 0px 0px;" >
					<router-view/>
				</v-container>
			</v-sheet>
		-->
		<v-container>
			<router-view></router-view>
		</v-container>
			
			
		</v-main>


		<v-footer color="primary lighten-1" padless>
			<v-row justify="center" no-gutters>
				<v-btn v-for="link in menu" :key="link.name" color="white" text rounded class="my-2">{{ link.name }}</v-btn>
				<v-col class="primary lighten-2 py-4 text-center white--text" cols="12">{{ new Date().getFullYear() }} - ВК Кондор</v-col>
				
			</v-row>
		</v-footer>
	</v-app>
</template>

<script>

//import CallToAction from '@/components/CallToAction.vue'

export default {
	name: 'App',
	components: {
		//CallToAction,
	},

	data: () => ({
		drawer: false,
		group:false,
		menu:[
			{name:'О клинике', link:'/#about', mdi:"mdi-information-outline"},
			{name:'Наши услуги', link:'/#services', mdi:"cog-outline"},
			{name:'Вопросы', link:'/#faq', mdi:'message-question-outline'},
			{name:'Контакты', link:'/#contacts', mdi:'map-marker-outline'},
		],
	}),
	beforeMount(){},
};
</script>

<style>
	@font-face {
		font-family: "RoadRadio";
		src: url("/fonts/roadradio_bold.otf") format("opentype");
	}
	@font-face {
		font-family: "RussoOne";
		src: url("/fonts/RussoOne-Regular.ttf") format("opentype");
	}

	.text-rr{
		font-family: "RoadRadio";
	}
	.clinic_name {
		font-family: "RoadRadio";
		font-size: 75px;
	}

	.obvodka {
		text-shadow: #FFF 1px 0 1px, #FFF 0 1px 1px, #FFF -1px 0 1px, #FFF 0 -1px 1px;
	}

	
	body {
		font-family: 'Roboto';
	}

	.background {
		background-image: url("/img/cat_and_rabbit.jpg");
		background-size: 635px;
		height: 635px;
		background-position: 100%;
	}


	.clinic_text_h2 {
		font-size: 45px;
		font-family: 'Roboto';
	}
	.clinic_text_display {
		font-size: 20px;
		font-family: 'Roboto';
	}
	.clinic_text_inform {
		font-size: 32px;
		font-family: "RussoOne";
	}

	.v-card {
		border-radius: 20px !important;
	}

@media (max-width: 768px){
	.clinic_text_h2 {
		font-size: 25px;
	}
	.clinic_text_display {
		font-size: 14px;
	}
} 


</style>


<v-col class="col-4 pa-0 text-center"><a href="https://t.me/kondor_vet"  class="dinamic_link"><center><v-img src="img/icon_telegram.png" height="24" width="24"></v-img></center></a></v-col>