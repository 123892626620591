<template>
	<div>
		<v-row>
			<v-col class="col-12">Адрес: Москва, ул.Старокалужская, д.62 стр. 4</v-col>
			<v-col class="col-12 ma-auto">
				<div style="position:relative;overflow:hidden;"><a href="https://yandex.ru/maps/org/kondor/13274471258/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:0px;">Кондор</a><a href="https://yandex.ru/maps/213/moscow/category/veterinary_clinic/184107216/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:14px;">Ветеринарная клиника в Москве</a><a href="https://yandex.ru/maps/213/moscow/category/veterinary_laboratory/13885210461/?utm_medium=mapframe&utm_source=maps" style="color:#eee;font-size:12px;position:absolute;top:28px;">Ветеринарная лаборатория в Москве</a><iframe src="https://yandex.ru/map-widget/v1/?ll=37.537541%2C55.660094&mode=search&oid=13274471258&ol=biz&tab=chain&z=16" width="560" height="400" frameborder="1" allowfullscreen="true" style="position:relative;"></iframe></div>
			</v-col>
		</v-row>
	</div>
</template>

<script type="text/javascript">


export default {
	name: 'MapComponent_MSK_V',
	components: {},
	data(){
		return {}
	},
}
</script>





