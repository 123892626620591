<template>
	<Block>
		<template v-slot:title>15 признаков посетить ветеринарного врача</template>
		<template v-slot:subtitle>Узнайте, когда следует обратится за помощью к ветеринарному врачу</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-8">
					<ul>
						<li v-for="s in signs" :key="s">{{ s }}</li>
					</ul>
					<br>
					<CallToAction></CallToAction>
				</v-col>
				<v-col class="col-12 col-sm-4">
					<v-img src="/img/backgound_visit.jpg" max-height="800" contain></v-img>
				</v-col>
			</v-row>
		</template>


	</Block>
</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'
import CallToAction from '@/components/CallToAction.vue'


export default {
	name: 'PageSignsToVisitVeterinarian',
	components: {
		Block, 
		CallToAction,
	},
	data() {
		return {
			signs: [
				"Высокая или низкая температура тела",
				"Кашель, выделения из носа, хрипы, истечения из глаз",
				"Рвота и/или диарея, вздутие (увеличение живота)",
				"Отказ от еды и/или воды",
				"Нарушение дефекации и затрудненное мочеиспускание",
				"Судороги или иные припадки",
				"Хромота, невозможность опереться на конечность, полная потеря опоры на лапы",
				"Зуд, прыщики на коже, алопеции (облысение), выпадение шерсти",
				"Питомец трясет ушами и головой",
				"Плохой запах из пасти, кровоточат десна, налет на зубах",
				"Тяжелое дыхание, дыхание с открытым ртом",
				"Потеря веса или ожирение",
				"Питомец много пьет и много мочится",
				"Питомец вялый, сонный, не хочет гулять, не реагирует на лакомства",
				"У питомца заметны опухоли, наросты, иные новообразования",
			],
		}
	}
}
</script>