<template>
	<v-row class="my-0 py-5 " :class="color" id="services" >
		<v-col class="col-12">
			<v-row><h2 class="col-lg-6 col-12 clinic_text_h2 font-weight-bold"><slot name="title"></slot></h2></v-row>
		</v-col>
		<v-col class="col-12">
			<v-row><h3 class="col-lg-6 col-12  clinic_text_display"><slot name="subtitle"></slot></h3></v-row>
		</v-col>
		<v-col class="col-12 ">
			<slot></slot>
		</v-col>
	</v-row>
</template>

<script type="text/javascript">
	export default {
		name: "Block",
		props: [ 'color', ],
	}
</script>
