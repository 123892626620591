<template>
		<div>
			<HEAD01></HEAD01>
			<!--
			<v-carousel :show-arrows="true">
				<v-carousel-item v-for="(item,i) in items" :key="i" :src="item.src" ></v-carousel-item>
			</v-carousel>
			-->
			<v-row>
				<v-col class="col-12"><v-img src="/img/Stock_Kastration_new_cost.jpg"></v-img></v-col>
				<v-row class="col-12" justify="center">
					<v-col class="col-lg-3 col-md-4 col-sm-6 col-12 py-0" style=""><CallToAction></CallToAction></v-col>
				</v-row>
			</v-row>

			<HEAD02></HEAD02>

			<PageStock></PageStock>

			<Services></Services>
			<About></About>
			<PageSignsToVisitVeterinarian></PageSignsToVisitVeterinarian>
			
			<PageFeedback></PageFeedback>
			<FAQ></FAQ>
			<Record></Record>
			<Contacts></Contacts>
			
		</div>
</template>

<script>
// @ is an alias to /src

import HEAD01 from '@/components/PageHead01.vue'
import HEAD02 from '@/components/PageHead02.vue'
import Services from '@/components/PageServices.vue'
import About from '@/components/PageAbout.vue'
import PageFeedback from '@/components/PageFeedback.vue'
import FAQ from '@/components/PageFAQ.vue'
import Record from '@/components/PageRecord.vue'
import Contacts from '@/components/PageContacts.vue'
import PageSignsToVisitVeterinarian from '@/components/PageSignsToVisitVeterinarian.vue'
import PageStock from '@/components/PageStock.vue'

import CallToAction from '@/components/CallToAction.vue'


export default {
	name: 'Home',
	components: {
		CallToAction,

		HEAD01,
		HEAD02,
		PageStock,
		Services,
		About,
		PageFeedback,
		FAQ,
		Record,
		Contacts,
		PageSignsToVisitVeterinarian,
	},
	data: () =>({
		items:[
			{ src: '/img/Stock_Kastration_new_cost.jpg',},
			{ src: 'https://cdn.vuetifyjs.com/images/carousel/sky.jpg',},
		]
	})
}
</script>



