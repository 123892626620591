<template>
	<v-btn color="primary" rounded block @click="autofocus" large>Записаться на прием</v-btn>
</template>

<script type="text/javascript">
export default {
	methods: {
		autofocus(){
			var f = document.getElementById('name')
			f.focus()
		}
	}
}
</script>