<template>
	<Block>
		<template v-slot:title>Работаем со всеми видами животных</template>
		<template v-slot:subtitle>В штате работают врачи с большим опытом работы с кошками, собаками, а так же экзотическими животными</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-6 col-md-4 col-lg-3 pa-0 ma-0" v-for="service in services" :key="service.name">
					<ServiceCard :name="service.name" :img='service.img'></ServiceCard>
				</v-col>
			</v-row>
		</template>
	</Block>
	
</template>

<script type="text/javascript">
//import CallToAction from '@/components/CallToAction.vue'
import ServiceCard from '@/components/ServiceCard.vue'
import Block from '@/components/Block.vue'


export default {
	name: 'HEAD01',
	components: {
		//CallToAction,
		ServiceCard,
		Block,
	},
	data(){
		return {
			services: [
				{'name':'Кошки', img:'/img/v_cat.png'},
				{'name':'Собаки', img:'/img/v_dog.png'},
				{'name':'Птицы', img:'/img/v_bird.png'},
				{'name':'Грызуны', img:'/img/v_rodent.png'},
				{'name':'Рептилии', img:'/img/v_turtle.png'},
				{'name':'Кролики', img:'/img/v_rabbit.png'},
				{'name':'Хорьки', img:'/img/v_fretka.png'},
			],
		}
	},

}
</script>
