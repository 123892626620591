<template>
	<Block id="contacts">
		<template v-slot:title>Приходите в нашу клинику</template>
		<template v-slot:subtitle>Ждем Вас в нашей клинике</template>
		<template>
			<MapComponent_MSK_V></MapComponent_MSK_V>
		</template>
	</Block>
</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'
import MapComponent_MSK_V from '@/components/MapComponent_MSK_V.vue'


export default {
	name: 'Contacts',
	components: {
		Block,
		MapComponent_MSK_V,
	},
	data(){
		return {}
	},
}
</script>





