<template>
	<Block>
		<template v-slot:title>Акции</template>
		<template v-slot:subtitle>Выберете подходящую акуцию и запишитесь на прием</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="service in services" :key="service.name">
					<ServiceCardDual :name="service.name" :img='service.logo' :text='service.text' color="white" :list="service.list" :cost="service.cost"></ServiceCardDual>
				</v-col>
			</v-row>
			
		</template>
	</Block>
</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'
import ServiceCardDual from '@/components/ServiceCardDual.vue'


export default {
	name: 'Services',
	components: {
		//CallToAction,
		ServiceCardDual,
		Block,
	},
	data(){
		return {

			services: [
				{'name':'World Spay Day 2023', 			logo:'/img/stock_01.jpg', text:'В честь Всемирного дня кастрации, в сети ВК Кондор проводится акция. В акции участвуют тлько кошки. \n Акция проходит 28.02.2023 (Новое Ступино) и 01.03.2023 (Москва)',},
				{'name':'Здоровые почки', logo:'/img/nefros.png', cost: '8500', list:[
						'Прием и осмотр',
						'Взятие крови', 
						'Общий анализ крови', 
						'Биохимический анализ крови', 
						'Анализ SDMA', 
						'Общий анализ мочи (мочу необходимо собрать самостоятельно)', 
						'УЗИ органов мочевыделения (почки, мочевой пузырь, уретра)', 
						'Заключение и рекомендации',
						]
				},
				{
					name:'Здоровый котенок', logo:'/img/cat_puppy.jpg', text:'', cost:'10000', list:[
						'Прием и осмотр',
						'Взятие крови',
						'Общий анализ крови',
						'Биохимический анализ крови',
						'Анализ на парвовирусный и коронавирусный энтерит, лейкоз, иммунодефицит, калицивироз и ринотрахеит',
						'Заключение и рекомендации',
						'Вакцинация двухкратная с заведением паспорта',
						'Чипирование',
						'Стрижка когтей и чистка ушей',
						]
				},
				{
					name:'Кастрация', logo:'/img/cat_kastration.jpg', text:'', cost:'6500', list:[
						'Прием и осмотр',
						'Взятие крови',
						'Общий анализ крови',
						'Биохимический анализ крови',
						'Анализ NT_ProBN (Сердце)',
						'УЗ Скрининг сердца',
						'Анестезия и расходные материлы',
						'Операция',
						'Выведение из наркоза',
						'5-и часовой стационар',
						]
				},
				{
					name:'Здоровый щенок', logo:'/img/dog_puppy.jpg', text:'', cost:'5600', list:[
						'Прием и осмотр',
						'Вакцинация двухкратная с оформлением паспорта',
						'Чипирование с занесением в базу',
						'Консультация по содержанию, кормлению, уходу, обработкам от паразитов и срокам кастрации',
						'Стрижка когтей',
						]
				},
				{
					name:'Здоровая собака', logo:'/img/dog.jpg', text:'', cost:'6500', list:[
						'Прием и осмотр',
						'Взятие крови',
						'Общий анализ крови',
						'Биохимический анализ крови',
						'Анализ мочи (необходимо собрать самостоятельно)',
						'Одно исследование (УЗИ Органа или Рентген 2 проекции)',
						'Заключение и рекомендации',
						]
				},
				{
					name:'Для собак с улицы', logo:'/img/dog_streen.jpg', text:'', cost:'6800', list:[
						'Прием и осмотр',
						'Взятие крови',
						'Общий анализ крови',
						'Биохимический анализ крови',
						'Смывы на парвовирус, коронавирус и лямблии',
						'Одно исследование (УЗИ органа или Рентген 2 проекции)',
						'Заключение и рекомендации',
						]
				},
			],
		}
	},
	methods:{},

}
</script>


<style>
	.clinic_text_48 {
		font-size: 48px;
		font-family: 'Roboto';
	}
	.clinic_text_20 {
		font-size: 20px;
		font-family: 'Roboto';
	}

</style>

