<template>
	<div>
		<v-sheet class="mx-auto my-2 pa-0 card" width="285" height="285">
			<v-card class="mx-auto front" width="285" height="285" :color='getColor()' :elevation="2">
				<v-row class="ma-0 pa-0">
					<v-col class="col-12 ma-0 pa-0"><v-card-title class="black--text" >{{ name }}</v-card-title></v-col>
					<v-col class="col-12 ma-auto pa-auto"><v-img class="ma-auto" max-height="150" max-width="150" contain aspect-ratio="1" :src="img"></v-img></v-col>
					<v-col class="col-12 text-right font-weight-bold">{{ cost }} <v-span v-if="cost">руб</v-span></v-col>
				</v-row>
			</v-card>
			<v-card class="mx-auto back" width="285" height="285" color="" :elevation="12" @click="autofocus">
				<v-row class="ma-0 pa-0">
					<v-col class="col-12 ma-0 pa-0"><v-card-title class="black--text" style="padding-top:0px; padding-block:0px" >{{ name }}</v-card-title></v-col>
					<v-card-text v-if="text">{{ text }}</v-card-text>
					<v-card-text v-if="list" class="ma-0 pa-0">
						<ul style="font-size:10px; ">
							<li v-for="(l,i) in list" :key="i" class="">{{ l }}</li>
						</ul>
					</v-card-text>
				</v-row>
			</v-card>
		</v-sheet>
	</div>

	
	
</template>


<script type="text/javascript">
	export default {
		name:"ServiceCardDual",
		props: [
			'name','img','text', 'color', 'list', 'cost',
		],
		methods:{
			getColor(){
				// Возвращаем цвет, если он указан, иначе ставим secondary
				if (this.color){
					return this.color
				} else {
					return 'secondary'
				}
			},
			autofocus(){
				var f = document.getElementById('name')
				f.focus()
			},
		},
	}
</script>

<style>


.front, .back {
  position: absolute;
  display: flex;
  justify-content: center;
  transition: 1s;
  backface-visibility: hidden;
}


.back {
  transform: rotateY(180deg);
}
.card:hover .front {
  transform: rotateY(180deg);}
.card:hover .back {
  transform: rotateY(360deg);}

</style>
