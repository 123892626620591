<template>
	<Block  id="calltoaction">
		<template v-slot:title>Запись на прием</template>
		<template v-slot:subtitle>Заполните данные и оператор перезвонит в течении 24 часов</template>
		<template>
			<v-row>
				<v-col class="col-lg-6">
					<v-img src="/img/background_call_2.png" max-height="500" contain></v-img>
				</v-col>
				<v-col class="col-lg-6 ">
					<RecordComponent></RecordComponent>
				</v-col>
			</v-row>
		</template>
	</Block>
</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'
import RecordComponent from '@/components/RecordComponent.vue'

export default {
	name: 'Record',
	components: {
		Block,
		RecordComponent,
	},
	data(){
		return {}
	},
}
</script>





