<template>
	<v-card class="mx-auto my-2 pa-0" width="285" height="285" color="secondary">
		<template slot="progress">
			<v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
		</template>
		<v-card-title class="black--text">{{ name }}</v-card-title>
		<v-img height="220" width="220" contain :src="img" class="mx-auto"></v-img>
	</v-card>
</template>


<script type="text/javascript">
	export default {
		name:"ServiceCard",
		props: [
			'name','img',
		],
	}
</script>