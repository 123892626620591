<template>
	<Block>
		<template v-slot:title>Услуги нашей клиники</template>
		<template v-slot:subtitle>Мы предлагаем Вам и Вашему питомцу качественную ветеринарную помощь, здоровье и долголетие</template>
		<template>
			<v-row>
				<v-col class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="service in services" :key="service.name">
					<ServiceCardDual :name="service.name" :img='service.logo' :text='service.text'></ServiceCardDual>
				</v-col>
			</v-row>
			
		</template>
	</Block>
</template>

<script type="text/javascript">
import Block from '@/components/Block.vue'
import ServiceCardDual from '@/components/ServiceCardDual.vue'


export default {
	name: 'Services',
	components: {
		//CallToAction,
		ServiceCardDual,
		Block,
	},
	data(){
		return {
			images: [
				'/img/draw_dog.png',
				'/img/draw_cat.png',
				'/img/draw_mouse.png',
				'/img/draw_turtle.png',
				'/img/draw_birdth.png',
			],
			services: [
				{'name':'Терапия', 			logo:'/img/v_teraphia.png', text:'Врач нашей ветклиники оценит состояние питомца, проведет необходимую диагностику, выявит патологию, назначит лечение и при необходимости направит к узкопрофильному специалисту.',},
				{'name':'Хирургия', 		logo:'/img/v_surgery.png', text:'Операции любой сложности: остеосинтез, ламинэктомия, удаление опухолей, расширение ноздрей, гинекологические, стоматологические. Ортопедические операции (патологии суставов, проблемы позвоночника и т. д.).'},
				{'name':'Стационар', 		logo:'/img/v_stacionar.png', text:'Круглосуточно: реанимация, подготовка к операциям, послеоперационное наблюдение, реабилитация. Стационарное отделение с доступом кислорода, индивидуальным освещением, системой вентиляции, подогреваемым полом.'},
				{'name':'Диагностика', 	logo:'/img/v_xray.png', text:'В отделении визуальной диагностики ультразвуковые, рентгенографические и кардиологические (ЭКГ, ЭХО и ЭХО-КГ) исследования проводятся на современных цифровых аппаратах экспертного уровня.'},
				{'name':'Лаборатория', 	logo:'/img/v_laboratory.png', text:'Все виды анализов для ваших питомцев. Собственная лаборатория, оборудованная новейшими анализаторами, позволяет нам всего за 30 минут получить результаты. Лаборатория работает круглосуточно.'},
				{'name':'Стоматология', logo:'/img/v_stomatolog.png', text:'Наша ветеринарная клиника оказывает весь спектр стоматологических услуг. Мы используем современные материалы, анестезирующие препараты и высокотехнологичное оборудование, соблюдается абсолютная стерильность. '},
				{'name':'Офтальмология',logo:'/img/v_oftalmolog.png', text:'Наши врачи-офтальмологи проводят полный спектр услуг для животных разных видов и возрастов. В ходе приема проводится полное офтальмологическое обследование всех структур глаза и придаточного аппарата.'},
				{'name':'Кардиология', 	logo:'/img/v_cariolog.png', text:'Наши врачи-кардиологи проводят полный спектр услуг для животных разных видов и возрастов. В ходе приема проводится полное кардиологическое обследование, ЭХО-КГ и аускультация'},
			],
		}
	},
	methods:{
		getDraw(){
			var num = Math.floor(Math.random()*this.images.length)
			console.log("getDraw:", num)
			return this.images[num]
		}
	},

}
</script>


<style>
	.clinic_text_48 {
		font-size: 48px;
		font-family: 'Roboto';
	}
	.clinic_text_20 {
		font-size: 20px;
		font-family: 'Roboto';
	}

</style>

