<template>
	<div>
		{{ name }} - {{ phone }}
		<v-layout align-center justify-center>
			<v-flex>
				<v-card class="elevation-12 rounded">
					<v-toolbar dark color="primary"><v-toolbar-title >Заказать обратный звонок</v-toolbar-title></v-toolbar>
					<v-card-text>
						<v-form>
							<v-text-field id="name"  name="name" v-model="name" label="Ваше имя" type="text" rounded outlined ></v-text-field>
							<v-text-field id="phone" name="phone" v-model="phone" label="Телефон" type="phone" rounded outlined></v-text-field>
							<v-checkbox v-model="contract" label="Я согласен с условием обработки персональных данных, правилами оказания онлайн-консультирования и условиями договора-оферты на оказание услуг"></v-checkbox>
						</v-form>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" to="/" rounded large :disabled="checkPreSend()" @click="sendRecord">Заказать</v-btn>
					</v-card-actions>
				</v-card>
			</v-flex>
		</v-layout>
	</div>
</template>

<script type="text/javascript">
//import CallToAction from '@/components/CallToAction.vue'
//import ServiceCard from '@/components/ServiceCard.vue'

export default {
	name: 'Record',
	components: {
		//CallToAction,
		//ServiceCard,
	},
	data(){
		return {
			name: '',
			phone: '',
			contract:false,
		}
	},
	methods: {
		sendRecord(){
			// Отправляем данные на сервер
			var phone = this.phone.replace(/[a-z ()-]/g,'')
			//action: PUT/GET(default)
			//client_phone              - * Телефон куда позвонить (+79637825419)
			//reason_for_petition       - * Причина запис (String)
			var url = 'http://lk.vetlight.ru/api/v1/clients/appointments_application'
			var data = {
				action:'PUT', 
				client_phone:phone,
				reason_for_petition:'Отправлен запрос '+ this.name + ' на запись. Требуется связаться с владельцем по телефону '+phone
			}
			// Отправка данных на запись
			fetch(url, {method: 'POST', body: JSON.stringify(data)})
			.then(response=>response.json())
			.then((res)=>{
				console.log(res)
				this.$router.push('/complite')
			})
			
			
		},
		checkPreSend(){
			// Проверяем указало ли имя и телефон в правильном формате, а так же установлен ли тег согласия на обработку данных
			if (this.contract){
				if (this.name){
					if (this.phone){
						// Удаляем все лишнее из телефона
						var phone = this.phone.replace(/[a-z ()-]/g,'')
						var len = 0
						if (phone.startsWith('+7')){
							len = phone.length
						} else if (phone.startsWith('8')){
							len = phone.length + 1
						} else {
							len = 0
						}
						if (len == 12) { return false}
					}
				}
			}
			return true
		},
	},
}
</script>
